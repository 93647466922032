import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getEvents } from 'services/hasura';

import { Home } from 'tutadoo-design-system/src/components/pages/Home';
import { useAuth0 } from '@auth0/auth0-react';
import useTawk from '../../hooks/useTawk';
import tw from 'twin.macro';
import Text from 'components/Text';
import { Spinner } from 'grommet';
import Layout from 'components/Layout';

const Sticky = tw.div`sticky top-0 z-50 bg-white w-full`;
const R = require('ramda');
const LoaderContainer = tw.div`grid h-screen place-items-center`;

const diff = function (a, b) {
  return new Date(b.end_date).getTime() - new Date(a.end_date).getTime();
};
//  && new Date(end_date) >= new Date()
export default function home() {
  const { isAuthenticated } = useAuth0();
  useTawk('https://embed.tawk.to/615c0e0025797d7a89025ada/1fh7pdd26');
  const now = new Date();
  const future = new Date();
  now.setMonth(now.getMonth() - 2);
  future.setMonth(now.getMonth() + 3);
  const [filter, setFilter] = useState<filters.Events>({
    filterSearchString: '%%',
    filterFrom: now.toISOString(),
    filterTo: future.toISOString(),
    filterLocation: { state: 'Nairobi', country: 'Kenya' }
  });
  const [firstLoad, setFirstLoad] = useState(true);
  // const [categories, setCategories] = useState<unknown>([]);
  const { data, refetch, loading } = useQuery(getEvents(null, true), { variables: filter });

  const onSearch = ({
    searchString,
    dateRange
  }: {
    searchString: string;
    dateRange: string[];
    location;
  }) => {
    setFilter({
      filterSearchString: `%${searchString}%`,
      filterFrom: dateRange[0],
      filterTo: dateRange[1],
      filterLocation: { state: 'Nairobi', country: 'Kenya' }
    });
  };

  useEffect(() => {
    refetch(filter);
  }, [filter]);
  useEffect(() => {
    if (firstLoad && !loading) {
      setFirstLoad(false);
    }
  }, [firstLoad, loading]);
  return (
    <Layout key="home" noPadding>
      {firstLoad ? (
        <div />
      ) : (
        <Home
          events={data?.events
            ? R.sort(
              diff,
              data?.events.filter(
                ({ status, end_date }) =>
                  ['soldout', 'published'].includes(status)
              )
            )
            : []
          }
          loading={loading}
          basePath=""
          onSearch={onSearch}
          authenticated={isAuthenticated}
        />
      )}
    </Layout>
  );
}
